import { CompanyPermissionType, CompanyType, UserType } from "../redux/types";

export function bitToMo(bit: number) {
  const mo = bit / 1048576;
  const round = Math.ceil(mo * 100) / 100;
  return round;
}

function isSlug(str: string) {
  const wordsArray = str.split("-");

  if (wordsArray.length >= 3 && isSiret(wordsArray[1])) {
    return true;
  } else return false;
}

function removeSlug(str: string) {
  const wordsArray = str.split("-");

  if (wordsArray.length >= 3 && isSiret(wordsArray.slice(-2)[0])) {
    wordsArray.pop() && wordsArray.pop();
    return wordsArray;
  } else if (wordsArray.length >= 3 && isSiret(wordsArray.slice(-1)[0])) {
    wordsArray.pop();
    return wordsArray;
  } else if (wordsArray.length >= 2 && !isNaN(parseInt(wordsArray.slice(-1)[0]))) {
    wordsArray.pop();
    return wordsArray;
  } else return wordsArray;
}

function isSiret(str: string) {
  return !isNaN(parseInt(str)) && str.length === 14;
}

export function slugToString(slug: string) {
  const wordsArray = removeSlug(slug);

  const result = wordsArray
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  return result;
}

export function pathToArray(path: string): string[] {
  const pathArray = path.split("?")[0]?.split("/") || [];

  pathArray.forEach((path: string, index: number) => {
    pathArray[index] = isSlug(path) ? slugToString(path) : path;
  });

  return pathArray;
}
//

export function isOwner(user: UserType, company: CompanyType): boolean {
  if (user?.companyPermissions) {
    let permissionIndex = user?.companyPermissions?.findIndex((permission) => permission?.company?.id == company?.id);

    if (permissionIndex !== -1) return user?.companyPermissions[permissionIndex]?.isOwner ?? false;
  } else {
    if (company?.companyPermissions) {
      let permissionIndex = company?.companyPermissions?.findIndex((permission) => permission?.user?.id == user?.id);

      if (permissionIndex !== -1) return company?.companyPermissions[permissionIndex]?.isOwner ?? false;
    }
  }

  return false;
}

export function isAdmin(user: UserType, company: CompanyType): boolean {
  if (user?.companyPermissions) {
    let permissionIndex = user?.companyPermissions?.findIndex((permission) => permission?.company?.id == company?.id);

    if (permissionIndex !== -1) {
      return user?.companyPermissions[permissionIndex]?.isAdmin ?? false;
    }
  } else if (company?.companyPermissions) {
    let permissionIndex = company?.companyPermissions?.findIndex((permission) => permission?.user?.id == user?.id);

    if (permissionIndex !== -1) return company?.companyPermissions[permissionIndex]?.isAdmin ?? false;
  }

  return false;
}

export function getCompanyPermission(user: UserType, company: CompanyType): CompanyPermissionType | null {
  if (user?.companyPermissions) {
    let permissionIndex = user.companyPermissions?.findIndex((permission) => permission?.company?.id == company?.id);

    if (permissionIndex !== -1) return user.companyPermissions[permissionIndex];
  } else if (company?.companyPermissions) {
    let permissionIndex = company.companyPermissions?.findIndex((permission) => permission?.user?.id == user?.id);

    if (permissionIndex !== -1) return company.companyPermissions[permissionIndex];
  }
  return null;
}

export function getCookie(document: any, name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts !== undefined && parts.length === 2) return parts.pop()?.split(";").shift();
  else return undefined;
}
